.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.right-border {
  border-right: 0.5px dashed #222222;
}

button:focus {
  outline: 0;
}

.App-link {
  color: #61dafb;
}

html,
body {
  margin: 0;
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Games-card {
  border-radius: 30px;
  position: relative;
  float: right;
  margin-right: 60px;
  margin-top: 60px;
  background: #ffffff;
  width: 300px;
  height: 400px;
  border: 1px solid #9292a0;
  box-shadow: 0 4px 8px 0 #9292a0, 0 6px 20px 0 #9292a0;
  overflow: scroll;
}

.Games-card:hover {
  box-shadow: 0 8px 16px 0 #9292a0, 0 12px 40px 0 #9292a0;
}

.Games-card-container {
  padding: 20px 20px;
}

.Games-card-team-name {
  margin-left: 100px;
  margin-top: 10px;
  font-family: "Ubuntu", "sans-serif";
  font-weight: 800;
  font-size: 18px;
}

.Team-logo {
  width: 70px;
  margin-left: 20px;
}

table {
  padding: 5px;
  width: 100%;
  height: 100%;
  font-family: "Ubuntu", "sans-serif";
}

th,
td {
  border-bottom: solid 0.25px #9292a0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Games-entry-win {
  background-color: #5bbc6166;
}

.Games-entry-loss {
  background-color: #ff000066;
}

.Games-entry-tie {
  background-color: #9292a066;
}

.content-left {
  border-right: 0.25px solid #9292a0;
}

.triple-chain {
  width: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.chains-table-header {
  font-size: 2.3rem;
}

tr:hover {
  background-color: #9292a066;
}

html,
body {
  margin: 0;
  padding: 0;
}

.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

.broken-chains {
  width: 130px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.error-text-header {
  font-family: "Ubuntu", "sans-serif";
  font-size: 1.6rem;
  font-weight: 500;
}

.error-text {
  font-family: "Ubuntu", "sans-serif";
  font-size: 1rem;
}

.loading {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
